.user {
  cursor: pointer;
  float: right;
  margin-right: 20px;
}
.userName {
  margin-right: 5px;
}
.userIcon {
  margin-right: 5px;
}